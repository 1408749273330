import React, { useState, useEffect } from "react";
import axios from "axios";
import PocketBase from "pocketbase";

const pb = new PocketBase("https://cards.app-fuji.pokeflute.com/");

const AddSets = () => {
  const [availableSets, setAvailableSets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [syncProgress, setSyncProgress] = useState(0);
  const [isSyncing, setIsSyncing] = useState(false);
  const [syncedSets, setSyncedSets] = useState(0);
  const [totalSets, setTotalSets] = useState(0);
  const [skippedSets, setSkippedSets] = useState(0);

  useEffect(() => {
    fetchSets();
  }, []);

  const fetchSets = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await axios.get('https://api.pokemontcg.io/v2/sets', {
        headers: {
          'X-Api-Key': process.env.REACT_APP_POKEMON_TCG_API_KEY,
        }
      });
      // Sort sets by release date (newest first)
      const sortedSets = response.data.data.sort((a, b) =>
        new Date(b.releaseDate) - new Date(a.releaseDate)
      );
      setAvailableSets(sortedSets);
      setTotalSets(sortedSets.length);
    } catch (err) {
      console.error("Error fetching sets:", err);
      setError("Failed to fetch sets from Pokemon TCG API");
    } finally {
      setLoading(false);
    }
  };

  const checkSetExists = async (setId) => {
    try {
      const records = await pb.collection("sets").getList(1, 1, {
        filter: `setId="${setId}"`,
      });
      return records.totalItems > 0;
    } catch (err) {
      console.error(`Error checking if set exists: ${setId}`, err);
      return false; // Assume it doesn't exist in case of error
    }
  };

  const saveSetToPocketBase = async (set) => {
    const setId = set.id;

    // Check if set already exists
    const exists = await checkSetExists(setId);
    if (exists) {
      console.log(`Set already exists, skipping: ${set.name}`);
      setSkippedSets(prev => prev + 1);
      return true; // Successfully processed (skipped)
    }

    console.log(`Saving set: ${set.name} (${setId})`);

    // Get detailed set info
    try {
      const detailedResponse = await axios.get(`https://api.pokemontcg.io/v2/sets/${setId}`, {
        headers: {
          'X-Api-Key': process.env.REACT_APP_POKEMON_TCG_API_KEY,
        }
      });

      const detailedSet = detailedResponse.data.data;

      const setData = {
        setId: detailedSet.id,
        name: detailedSet.name,
        series: detailedSet.series,
        printedTotal: detailedSet.printedTotal,
        total: detailedSet.total,
        legalitiesUnlimited: detailedSet.legalities.unlimited,
        legalitiesStandard: detailedSet.legalities.standard,
        legalitiesExpanded: detailedSet.legalities.expanded,
        ptcgoCode: detailedSet.ptcgoCode,
        releaseDate: detailedSet.releaseDate,
        updatedAt: detailedSet.updatedAt,
        symbolImage: detailedSet.images.symbol,
        logoImage: detailedSet.images.logo,
        // Store the original JSON for reference
        rawData: detailedSet
      };

      const saveResponse = await pb.collection("sets").create(setData);
      console.log(`Successfully saved set: ${set.name}`, saveResponse);
      return true;
    } catch (err) {
      console.error(`Error saving set ${set.name} to PocketBase:`, err);
      setError(`Failed to save set: ${set.name}`);
      return false;
    }
  };

  const syncAllSets = async () => {
    setIsSyncing(true);
    setSuccess("");
    setError("");
    setSyncedSets(0);
    setSkippedSets(0);
    setSyncProgress(0);

    try {
      let successCount = 0;

      for (let i = 0; i < availableSets.length; i++) {
        const set = availableSets[i];
        const success = await saveSetToPocketBase(set);

        if (success) {
          successCount++;
          setSyncedSets(successCount);
        }

        // Update progress
        const progress = ((i + 1) / availableSets.length) * 100;
        setSyncProgress(progress);
      }

      setSuccess(`Successfully processed ${successCount} sets. (${skippedSets} were already in the database)`);
    } catch (err) {
      console.error("Error syncing sets:", err);
      setError("Error occurred while syncing sets");
    } finally {
      setIsSyncing(false);
    }
  };

  const syncSingleSet = async (set) => {
    setIsSyncing(true);
    setSuccess("");
    setError("");
    setSkippedSets(0);
    setSyncProgress(0);

    try {
      const success = await saveSetToPocketBase(set);
      setSyncProgress(100);

      if (success) {
        setSyncedSets(1);
        if (skippedSets > 0) {
          setSuccess(`Set "${set.name}" already exists in database.`);
        } else {
          setSuccess(`Successfully saved set "${set.name}".`);
        }
      } else {
        setError(`Failed to save set "${set.name}".`);
      }
    } catch (err) {
      console.error(`Error syncing set ${set.name}:`, err);
      setError(`Error occurred while syncing set "${set.name}"`);
    } finally {
      setIsSyncing(false);
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-6 text-center">Pokemon TCG Set Manager</h1>

      <div className="flex justify-center mb-6">
        <button
          onClick={syncAllSets}
          disabled={isSyncing || loading || availableSets.length === 0}
          className={`bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-6 rounded-lg focus:outline-none focus:shadow-outline ${(isSyncing || loading || availableSets.length === 0) ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          Sync All Sets to Database
        </button>
      </div>

      {/* Progress Bar */}
      {isSyncing && (
        <div className="mb-6">
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium">
              Syncing Sets: {syncedSets} of {totalSets} ({Math.round(syncProgress)}%)
              {skippedSets > 0 && ` - Skipped: ${skippedSets}`}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className="bg-purple-600 h-4 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${syncProgress}%` }}
            />
          </div>
        </div>
      )}

      {/* Messages */}
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      {success && <div className="text-green-500 text-center mb-4">{success}</div>}
      {loading && <div className="text-center mb-4">Loading sets...</div>}

      <div className="mt-8">
        <h2 className="text-xl font-semibold mb-4">Available Sets ({availableSets.length})</h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
          {availableSets.map(set => (
            <div key={set.id} className="border rounded-lg p-4 shadow hover:shadow-md transition-shadow">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-bold">{set.name}</h3>
                  <p className="text-sm text-gray-600">{set.series}</p>
                  <p className="text-xs text-gray-500">Released: {set.releaseDate}</p>
                  <p className="text-xs text-gray-500">Cards: {set.printedTotal}/{set.total}</p>
                </div>
                {set.images.symbol && (
                  <img
                    src={set.images.symbol}
                    alt={`${set.name} symbol`}
                    className="h-8 w-8"
                  />
                )}
              </div>

              {set.images.logo && (
                <img
                  src={set.images.logo}
                  alt={`${set.name} logo`}
                  className="mt-2 h-12 object-contain"
                />
              )}

              <div className="mt-4 flex justify-end">
                <button
                  onClick={() => syncSingleSet(set)}
                  disabled={isSyncing}
                  className={`bg-blue-500 hover:bg-blue-700 text-white text-sm py-1 px-3 rounded focus:outline-none focus:shadow-outline ${isSyncing ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  Sync Set
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AddSets;
