import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import PocketBase from "pocketbase";

/* const pb = new PocketBase("https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/"); */
const pb = new PocketBase(
  "https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/"
);

const Sync = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [failedCards, setFailedCards] = useState([]);
  const [processingComplete, setProcessingComplete] = useState(false);

  // New state variables for bulk sync
  const [bulkSyncActive, setBulkSyncActive] = useState(false);
  const [bulkSyncProgress, setBulkSyncProgress] = useState({
    currentPage: 0,
    totalPages: 0,
    processedCards: 0,
    addedCards: 0,
    skippedCards: 0,
  });

  // Reference to the failed cards section for scrolling
  const failedCardsRef = useRef(null);

  // Function to scroll to failed cards section
  const scrollToFailedCards = () => {
    failedCardsRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const checkCardExists = async (cardEeid) => {
    try {
      const records = await pb.collection('cards_031225_duplicate').getList(1, 1, { // cards_031125
        filter: `cardEeid="${cardEeid}"`,
      });
      return records.totalItems > 0;
    } catch (err) {
      console.error("Error checking if card exists:", err);
      return false;
    }
  };

  const saveCardToPocketBase = async (card) => {
    const cardEeid =
      card.name +
      "-" +
      card.number +
      "/" +
      card.set.printedTotal +
      "-" +
      card.set.id +
      "-" +
      card.regulationMark;
    const cardId = card.number + "/" + card.set.printedTotal; // Create a unique cardId

    // Check if the card already exists
    const exists = await checkCardExists(cardEeid);
    if (exists) {
      console.log(`Card ${card.name} (${cardId}) already exists, skipping...`);
      return;
    }

    console.log(`Saving card: ${card.name} with cardId: ${cardId}`); // Log before saving

    // Check if card has no rarity but is from a special set
    let cardRarity = card.rarity || "";

    if (!cardRarity) {
      const setNameLower = (card.set.name || "").toLowerCase();

      if (setNameLower.includes("mcdonald")) {
        cardRarity = "Collab";
      } else if (setNameLower.includes("kit")) {
        cardRarity = "Kit";
      } else if (setNameLower.includes("futsal")) {
        cardRarity = "Futsal";
      } else if (setNameLower.includes("rumble")) {
        cardRarity = "Rumble";
      }
    }

    const cardData = {
      cardfile: card,
      /* Name */
      name: card.name,
      /* Id */
      cardId: cardId,
      /* regulation mark */
      cardRegulationMark: card.regulationMark,
      cardEeid: cardEeid,
      /* Set */
      setid: card.set.id,
      setname: card.set.name,
      setseries: card.set.series,
      setprintedtotal: card.set.printedTotal,
      settotal: card.set.total,
      setlegalitiesstandard: card.set.legalities.standard,
      setlegalitiesunlimited: card.set.legalities.unlimited,
      setlegalitiesexpanded: card.set.legalities.expanded,
      setptcgoCode: card.set.ptcgoCode,
      setreleaseDate: card.set.releaseDate,
      setupdatedAt: card.set.updatedAt,
      setimagesymbol: card.set.images.symbol,
      setimagelogo: card.set.images.logo,
      /* number, artist y rarity */
      number: card.number,
      artist: card.artist,
      rarity: cardRarity,
      /* nationalPokedexNumbers */
      nationalPokedexNumbers: Array.isArray(card.nationalPokedexNumbers)
        ? card.nationalPokedexNumbers.join(", ")
        : "",
      nationalPokedexNumbers_one:
        Array.isArray(card.nationalPokedexNumbers) &&
          card.nationalPokedexNumbers[0]
          ? card.nationalPokedexNumbers[0]
          : "",
      nationalPokedexNumbers_two:
        Array.isArray(card.nationalPokedexNumbers) &&
          card.nationalPokedexNumbers[1]
          ? card.nationalPokedexNumbers[1]
          : "",
      nationalPokedexNumbers_three:
        Array.isArray(card.nationalPokedexNumbers) &&
          card.nationalPokedexNumbers[2]
          ? card.nationalPokedexNumbers[2]
          : "",
      /* General */
      hp: card.hp,
      rules: card.rules?.join(", ") || "",
      types: card.types,
      attack_one: card.attacks?.[0]?.name || "",
      attack_one_damage: card.attacks?.[0]?.damage || "",
      attack_one_description: card.attacks?.[0]?.text || "",
      attack_one_energy_cost_type_one: card.attacks?.[0]?.cost[0] || "",
      attack_one_energy_cost_type_two: card.attacks?.[0]?.cost[1] || "",
      attack_one_energy_cost_type_three: card.attacks?.[0]?.cost[2] || "",
      attack_one_energy_cost_type_four: card.attacks?.[0]?.cost[3] || "",
      attack_one_energy_cost_type_five: card.attacks?.[0]?.cost[4] || "",
      attack_two: card.attacks?.[1]?.name || "",
      attack_two_damage: card.attacks?.[1]?.damage || "",
      attack_two_description: card.attacks?.[1]?.text || "",
      attack_two_energy_cost_type_one: card.attacks?.[1]?.cost[0] || "",
      attack_two_energy_cost_type_two: card.attacks?.[1]?.cost[1] || "",
      attack_two_energy_cost_type_three: card.attacks?.[1]?.cost[2] || "",
      attack_two_energy_cost_type_four: card.attacks?.[1]?.cost[3] || "",
      attack_two_energy_cost_type_five: card.attacks?.[1]?.cost[4] || "",
      hability: card.abilities?.[0]?.name || "",
      hability_description: card.abilities?.[0]?.text || "",
      weakness: card.weaknesses?.[0]?.type || "",
      resistance: card.resistances?.[0]?.type || "",
      retreatCost: card.retreatCost?.join(", ") || "",
      /* New fields */
      convertedRetreatCost: card.convertedRetreatCost || "",
      subtypes: Array.isArray(card.subtypes) ? card.subtypes.join(", ") : "",
      subtypes_1: Array.isArray(card.subtypes) && card.subtypes[0] ? card.subtypes[0] : "",
      subtypes_2: Array.isArray(card.subtypes) && card.subtypes[1] ? card.subtypes[1] : "",
      subtypes_3: Array.isArray(card.subtypes) && card.subtypes[2] ? card.subtypes[2] : "",
      supertype: card.supertype || "",
      /* legalities */
      legalitiesstandard: card.legalities.standard,
      legalitiesunlimited: card.legalities.unlimited,
      legalitiesexpanded: card.legalities.expanded,
      /* images */
      imagesmall: card.images.small,
      imageslarge: card.images.large,
    };
    try {
      const saveResponse = await pb.collection("cards_031225_duplicate").create(cardData); // cards_031125
      console.log(`Successfully saved: ${cardId}`, saveResponse); // Log on success
    } catch (err) {
      console.error(`Error saving card ${cardId} to PocketBase:`, err);
      // Add the failed card to our list with the error message
      setFailedCards(prev => [...prev, {
        card,
        error: err.message || "Unknown error occurred",
        timestamp: new Date().toISOString()
      }]);
    }
  };

  const fetchCardsByName = async (name) => {
    setLoading(true);
    setError("");
    // Reset failed cards on new search
    setFailedCards([]);
    setProcessingComplete(false);
    let page = 1;
    let cards = [];
    try {
      while (true) {
        const response = await axios.get(
          `https://api.pokemontcg.io/v2/cards?q=name:${name}*&page=${page}&pageSize=250`,
          {
            headers: {
              "X-Api-Key": process.env.REACT_APP_POKEMON_TCG_API_KEY,
            },
          }
        );
        cards = [...cards, ...response.data.data];
        if (response.data.data.length < 250) {
          break; // No more results
        }
        page++;
      }
      setCards(cards);
      for (const card of cards) {
        await saveCardToPocketBase(card); // Save each card to PocketBase sequentially
      }
      // Mark processing as complete after all cards are processed
      setProcessingComplete(true);
    } catch (err) {
      console.error("Error fetching cards:", err);
      setError("Failed to fetch cards. Please try again.");
      setCards([]);
      setProcessingComplete(true); // Still mark as complete even if there's an error
    } finally {
      setLoading(false);
    }
  };

  // Add state to track if sync was manually cancelled
  const [manualCancel, setManualCancel] = useState(false);

  // Reset manual cancel when bulkSyncActive changes to false
  useEffect(() => {
    if (!bulkSyncActive) {
      setManualCancel(false);
    }
  }, [bulkSyncActive]);

  // Improved bulk sync function with better error handling
  const startBulkSync = async () => {
    setBulkSyncActive(true);
    setManualCancel(false);
    setLoading(true);
    setError("");
    setFailedCards([]);
    setProcessingComplete(false);

    // Reset progress
    setBulkSyncProgress({
      currentPage: 1,
      totalPages: 0,
      processedCards: 0,
      addedCards: 0,
      skippedCards: 0,
    });

    try {
      console.log("Starting bulk sync...");

      // Create an axios instance with timeout and retry logic
      const api = axios.create({
        timeout: 30000, // 30 second timeout
        headers: {
          "X-Api-Key": process.env.REACT_APP_POKEMON_TCG_API_KEY || "",
        }
      });

      // Get first page to determine total count
      const initialResponse = await api.get("https://api.pokemontcg.io/v2/cards?page=1&pageSize=100");

      if (!initialResponse.data || !initialResponse.data.data) {
        throw new Error("Invalid API response format");
      }

      const totalCount = initialResponse.data.totalCount || initialResponse.data.count;
      const totalPages = Math.ceil(totalCount / 100);

      console.log(`Found ${totalCount} cards across ${totalPages} pages`);

      setBulkSyncProgress(prev => ({
        ...prev,
        totalPages: totalPages
      }));

      // Process the first batch
      await processBatch(initialResponse.data.data);

      // Process remaining pages
      for (let page = 2; page <= totalPages; page++) {
        if (manualCancel) {
          console.log("Bulk sync was manually cancelled");
          break;
        }

        setBulkSyncProgress(prev => ({
          ...prev,
          currentPage: page
        }));

        try {
          console.log(`Fetching page ${page} of ${totalPages}`);
          const response = await api.get(`https://api.pokemontcg.io/v2/cards?page=${page}&pageSize=100`);

          if (response.data && response.data.data) {
            await processBatch(response.data.data);
          } else {
            console.error("Invalid response format on page", page);
            setError(prev => `${prev ? prev + "\n" : ""}Error on page ${page}: Invalid response format`);
          }
        } catch (pageError) {
          console.error(`Error fetching page ${page}:`, pageError);
          setError(prev => `${prev ? prev + "\n" : ""}Error on page ${page}: ${pageError.message}`);

          // Wait a bit before retrying
          await new Promise(resolve => setTimeout(resolve, 2000));

          // Retry once
          try {
            console.log(`Retrying page ${page}...`);
            const retryResponse = await api.get(`https://api.pokemontcg.io/v2/cards?page=${page}&pageSize=100`);
            if (retryResponse.data && retryResponse.data.data) {
              await processBatch(retryResponse.data.data);
            }
          } catch (retryError) {
            console.error(`Retry failed for page ${page}:`, retryError);
            // Continue to next page despite errors
          }
        }
      }

      console.log("Bulk sync completed successfully");
    } catch (err) {
      console.error("Error during bulk sync:", err);
      setError(`Bulk sync error: ${err.message}`);
    } finally {
      setLoading(false);
      setProcessingComplete(true);
      setBulkSyncActive(false);
    }
  };

  // Improved batch processing with better error handling
  const processBatch = async (cardBatch) => {
    console.log(`Processing batch of ${cardBatch.length} cards`);

    for (const card of cardBatch) {
      if (manualCancel) break;

      try {
        // Fix for cardEeid construction - handle potential undefined regulationMark
        const regulationMark = card.regulationMark || ""; // Use empty string if undefined

        const cardEeid =
          card.name +
          "-" +
          card.number +
          "/" +
          card.set.printedTotal +
          "-" +
          card.set.id +
          "-" +
          regulationMark;

        // Check if card exists
        const exists = await checkCardExists(cardEeid);

        if (exists) {
          // Skip existing card
          setBulkSyncProgress(prev => ({
            ...prev,
            processedCards: prev.processedCards + 1,
            skippedCards: prev.skippedCards + 1
          }));
        } else {
          // Save new card
          await saveCardToPocketBase(card);
          setBulkSyncProgress(prev => ({
            ...prev,
            processedCards: prev.processedCards + 1,
            addedCards: prev.addedCards + 1
          }));
        }
      } catch (err) {
        console.error(`Error processing card ${card?.name || card?.id || "unknown"}:`, err);

        // Add to failed cards if we have the card data
        if (card) {
          setFailedCards(prev => [...prev, {
            card,
            error: err.message || "Unknown error occurred",
            timestamp: new Date().toISOString()
          }]);
        }

        // Increment processed even if there's an error
        setBulkSyncProgress(prev => ({
          ...prev,
          processedCards: prev.processedCards + 1
        }));
      }

      // Small delay to prevent overwhelming the API
      await new Promise(resolve => setTimeout(resolve, 100));
    }
  };

  // Function to cancel bulk sync
  const cancelBulkSync = () => {
    setManualCancel(true);
    console.log("Cancellation requested. Finishing current batch...");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      fetchCardsByName(searchTerm);
    }
  };

  const handleCardSelect = (cardId) => {
    const card = cards.find((card) => card.id === cardId);
    setSelectedCard(card);
  };

  // Function to retry saving failed cards
  const retryFailedCards = async () => {
    setLoading(true);
    setProcessingComplete(false);
    const cardsToRetry = [...failedCards];
    setFailedCards([]);

    try {
      for (const failedItem of cardsToRetry) {
        await saveCardToPocketBase(failedItem.card);
      }
    } catch (err) {
      console.error("Error retrying failed cards:", err);
    } finally {
      setLoading(false);
      setProcessingComplete(true);
    }
  };

  // Function to reload the entire search
  const reloadSearch = () => {
    if (searchTerm.trim()) {
      fetchCardsByName(searchTerm);
    }
  };

  // Format JSON for display
  const formatJSON = (obj) => {
    return JSON.stringify(obj, null, 2);
  };

  return (
    <div className="container mx-auto px-4">
      {/* Sticky notification for failed cards */}
      {failedCards.length > 0 && (
        <div className="fixed bottom-4 right-4 bg-red-500 text-white rounded-lg shadow-lg p-4 z-50 flex items-center">
          <div className="mr-3">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          <div>
            <span className="font-bold">
              {processingComplete
                ? `Total Errors found: ${failedCards.length}`
                : `${failedCards.length} card(s) failed to save`}
            </span>
          </div>
          <button
            onClick={scrollToFailedCards}
            className="ml-4 bg-white text-red-500 px-3 py-1 rounded-md font-medium hover:bg-red-100 transition-colors"
          >
            View Errors
          </button>
        </div>
      )}

      {/* Search form with Bulk Sync option */}
      <div className="mt-5">
        <div className="flex flex-col sm:flex-row items-center justify-center gap-4">
          {/* Regular search form */}
          <form
            onSubmit={handleSubmit}
            className="flex items-center justify-center w-full sm:w-auto"
          >
            <input
              type="text"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              placeholder="Enter Pokemon Name"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
            />
            <button
              type="submit"
              disabled={loading}
              className="ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-blue-300"
            >
              Search
            </button>
          </form>

          {/* Bulk sync button */}
          <div className="w-full sm:w-auto">
            {!bulkSyncActive ? (
              <button
                onClick={startBulkSync}
                disabled={loading}
                className="w-full sm:w-auto bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline disabled:bg-green-300"
              >
                Bulk Sync All Cards
              </button>
            ) : (
              <button
                onClick={cancelBulkSync}
                className="w-full sm:w-auto bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              >
                Cancel Sync
              </button>
            )}
          </div>
        </div>

        {/* Bulk sync progress */}
        {bulkSyncActive && (
          <div className="mt-4 bg-blue-50 border border-blue-200 rounded-lg p-4">
            <h3 className="text-lg font-semibold text-blue-700 mb-2">
              Bulk Sync Progress {manualCancel && "(Cancelling...)"}
            </h3>
            <div className="space-y-2">
              <div className="flex justify-between">
                <span>Current Page:</span>
                <span className="font-medium">{bulkSyncProgress.currentPage} of {bulkSyncProgress.totalPages}</span>
              </div>
              <div className="w-full bg-gray-200 rounded-full h-2.5">
                <div
                  className="bg-blue-600 h-2.5 rounded-full transition-all duration-500 ease-in-out"
                  style={{ width: `${Math.max(1, (bulkSyncProgress.currentPage / Math.max(1, bulkSyncProgress.totalPages)) * 100)}%` }}
                ></div>
              </div>
              <div className="flex flex-wrap justify-between text-sm gap-2">
                <div className="px-2 py-1 bg-blue-100 rounded-lg">
                  <span>Processed: {bulkSyncProgress.processedCards}</span>
                </div>
                <div className="px-2 py-1 bg-green-100 rounded-lg">
                  <span>Added: {bulkSyncProgress.addedCards}</span>
                </div>
                <div className="px-2 py-1 bg-gray-100 rounded-lg">
                  <span>Skipped: {bulkSyncProgress.skippedCards}</span>
                </div>
                <div className="px-2 py-1 bg-red-100 rounded-lg">
                  <span>Errors: {failedCards.length}</span>
                </div>
              </div>
              {error && <p className="text-sm text-red-500 mt-2">{error}</p>}
            </div>
          </div>
        )}
      </div>

      {/* Display loading/error messages */}
      {loading && !bulkSyncActive && <p className="text-center mt-5">Loading...</p>}
      {error && <p className="text-center mt-5 text-red-500">{error}</p>}

      {/* Regular search results */}
      {!bulkSyncActive && (
        <div className="flex flex-col items-center mt-5">
          <p className="text-center">Total: {cards.length}</p>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
            {cards.map((card) => (
              <div
                key={card.id}
                onClick={() => handleCardSelect(card.id)}
                className="cursor-pointer"
              >
                {card.name} - {card.number}/{card.set.printedTotal}
                <img
                  src={card.images.small}
                  alt={card.name}
                  className="w-full h-auto"
                />
              </div>
            ))}
          </div>
          {selectedCard && (
            <div className="flex flex-row justify-between mt-5 max-w-4xl">
              <div className="flex flex-col justify-between">
                <h2 className="text-2xl font-bold">{selectedCard.name}</h2>
                <p>
                  <strong>Type:</strong> {selectedCard.types}
                </p>
                <img
                  src={selectedCard.images.large}
                  alt={selectedCard.name}
                  className="w-1/4 h-auto"
                />
                <p>
                  <strong>Set:</strong> {selectedCard.set.name}
                </p>
                <p>
                  <strong>Number:</strong> {selectedCard.number}
                </p>
                <p>
                  <strong>Artist:</strong> {selectedCard.artist}
                </p>
                <p>
                  <strong>Rarity:</strong> {selectedCard.rarity}
                </p>
              </div>
              <div className="flex flex-col justify-between">
                {selectedCard.hp && (
                  <p>
                    <strong>HP:</strong> {selectedCard.hp}
                  </p>
                )}
                {selectedCard.rarity && (
                  <p className="hidden">
                    <strong>Rarity:</strong> {selectedCard.rarity}
                  </p>
                )}
                {selectedCard.attacks && (
                  <div>
                    <h3 className="font-bold">Attacks:</h3>
                    {selectedCard.attacks.map((attack, index) => (
                      <div key={index} className="mb-2">
                        <p>
                          <strong>Name:</strong> {attack.name}
                        </p>
                        <p>
                          <strong>Damage:</strong> {attack.damage}
                        </p>
                        {/*                       <p><strong>Cost:</strong> {attack.cost.join(', ')}</p>
                         */}{" "}
                        {attack.text && (
                          <p>
                            <strong>Text:</strong> {attack.text}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                )}
                {selectedCard.weaknesses && (
                  <div>
                    <h3 className="font-bold">Weaknesses:</h3>
                    {selectedCard.weaknesses.map((weakness, index) => (
                      <p key={index}>
                        {weakness.type} {weakness.value}
                      </p>
                    ))}
                  </div>
                )}
                {selectedCard.resistances && (
                  <div>
                    <h3 className="font-bold">Resistances:</h3>
                    {selectedCard.resistances.map((resistance, index) => (
                      <p key={index}>
                        {resistance.type} {resistance.value}
                      </p>
                    ))}
                  </div>
                )}
                {selectedCard.abilities && (
                  <div>
                    <h3 className="font-bold">Abilities:</h3>
                    {selectedCard.abilities.map((ability, index) => (
                      <p key={index}>
                        <strong>Name:</strong> {ability.name}
                      </p>
                    ))}
                  </div>
                )}
                {selectedCard.retreatCost && (
                  <p>
                    <strong>Retreat Cost:</strong>{" "}
                    {selectedCard.retreatCost.join(", ")}
                  </p>
                )}
                {selectedCard.rules && (
                  <div>
                    <h3 className="font-bold">Rules:</h3>
                    {selectedCard.rules.map((rule, index) => (
                      <p key={index}>{rule}</p>
                    ))}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      )}

      {/* Failed Cards Section with ref */}
      {failedCards.length > 0 && (
        <div ref={failedCardsRef} className="w-full mt-10 border-t-2 border-red-500 pt-4">
          <h2 className="text-2xl font-bold text-red-500 text-center mb-4">
            {processingComplete ? `Total Errors found: ${failedCards.length}` : `Failed Cards (${failedCards.length})`}
          </h2>
          <div className="space-y-8">
            {failedCards.map((failedItem, index) => (
              <div key={index} className="bg-red-50 rounded-lg shadow-lg p-4 border border-red-300">
                <div className="flex flex-wrap md:flex-nowrap gap-4">
                  {/* Card Image */}
                  <div className="w-full md:w-1/4">
                    <img
                      src={failedItem.card.images.small}
                      alt={failedItem.card.name}
                      className="w-full rounded-lg"
                    />
                  </div>

                  {/* Card Details */}
                  <div className="w-full md:w-3/4">
                    <h3 className="text-xl font-bold">{failedItem.card.name}</h3>
                    <p className="text-red-600 font-semibold">Error: {failedItem.error}</p>
                    <p className="mb-2">
                      <strong>Set:</strong> {failedItem.card.set.name} - {failedItem.card.number}/{failedItem.card.set.printedTotal}
                    </p>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
                      <p><strong>Card ID:</strong> {failedItem.card.number}/{failedItem.card.set.printedTotal}</p>
                      <p><strong>Rarity:</strong> {failedItem.card.rarity || 'N/A'}</p>
                      <p><strong>Artist:</strong> {failedItem.card.artist || 'N/A'}</p>
                      <p><strong>HP:</strong> {failedItem.card.hp || 'N/A'}</p>
                      <p><strong>Types:</strong> {failedItem.card.types ? failedItem.card.types.join(', ') : 'N/A'}</p>
                      <p><strong>Supertype:</strong> {failedItem.card.supertype || 'N/A'}</p>
                      <p><strong>Subtypes:</strong> {failedItem.card.subtypes ? failedItem.card.subtypes.join(', ') : 'N/A'}</p>
                    </div>

                    {/* Expandable Details for Advanced Users */}
                    <details className="mt-3">
                      <summary className="cursor-pointer font-semibold text-blue-600">
                        View Full Card Data (for manual entry)
                      </summary>
                      <pre className="mt-2 p-3 bg-gray-100 rounded text-xs overflow-auto max-h-60">
                        {formatJSON({
                          name: failedItem.card.name,
                          cardId: failedItem.card.number + "/" + failedItem.card.set.printedTotal,
                          setName: failedItem.card.set.name,
                          setId: failedItem.card.set.id,
                          // Include other key fields needed for manual database entry
                          cardEeid: failedItem.card.name + "-" + failedItem.card.number + "/" +
                            failedItem.card.set.printedTotal + "-" + failedItem.card.set.id + "-" +
                            failedItem.card.regulationMark,
                          types: failedItem.card.types,
                          hp: failedItem.card.hp,
                          images: failedItem.card.images,
                          // You can add more fields as needed
                        })}
                      </pre>
                    </details>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Retry options */}
          <div className="flex justify-center space-x-4 mt-8 mb-6">
            <button
              onClick={retryFailedCards}
              disabled={loading}
              className="bg-orange-400 hover:bg-orange-500 text-white font-bold py-2 px-6 rounded-lg shadow-md transition-colors"
            >
              {loading ? "Processing..." : "Retry Failed Cards"}
            </button>
            <button
              onClick={reloadSearch}
              disabled={loading}
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-6 rounded-lg shadow-md transition-colors"
            >
              {loading ? "Loading..." : "Reload All"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sync;
