import React from "react";
import { Link } from "react-router-dom";

const SetSync = () => {
  return (
    <div className="container mx-auto px-4 py-12">
      <h1 className="text-3xl font-bold mb-8 text-center">Pokemon TCG Database Tools</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
        <Link
          to="/sync"
          className="bg-blue-600 hover:bg-blue-700 text-white p-6 rounded-lg shadow-lg flex flex-col items-center justify-center transition-all hover:-translate-y-1"
        >
          <h2 className="text-xl font-bold mb-2">Card Sync Tool</h2>
          <p className="text-center">
            Synchronize Pokemon cards from the Pokemon TCG API to your database.
            Search by name or sync entire sets.
          </p>
        </Link>

        <Link
          to="/addsets"
          className="bg-purple-600 hover:bg-purple-700 text-white p-6 rounded-lg shadow-lg flex flex-col items-center justify-center transition-all hover:-translate-y-1"
        >
          <h2 className="text-xl font-bold mb-2">Set Manager</h2>
          <p className="text-center">
            Manage Pokemon TCG sets. Add new sets to your database or update existing ones.
          </p>
        </Link>
      </div>
    </div>
  );
};

export default SetSync;