import React, { useState, useEffect } from "react";
import axios from "axios";
import PocketBase from "pocketbase";

/* const pb = new PocketBase("https://tkgssggcs4wwo8kwkos88sgk.app-palma.teide.app/"); */
const pb = new PocketBase(
  "https://cards.app-fuji.pokeflute.com/"
);

const Sync = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [cards, setCards] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [selectedSet, setSelectedSet] = useState("");
  const [availableSets, setAvailableSets] = useState([]);
  // New state variables for tracking sync progress
  const [syncedCards, setSyncedCards] = useState(0);
  const [totalToSync, setTotalToSync] = useState(0);
  const [isSyncing, setIsSyncing] = useState(false);
  const [isSyncingAll, setIsSyncingAll] = useState(false);
  const [isMultiSetSync, setIsMultiSetSync] = useState(false);
  const [currentSyncSetIndex, setCurrentSyncSetIndex] = useState(0);
  const [skippedCards, setSkippedCards] = useState(0);

  // Check if a card already exists in PocketBase by its cardEeid
  const checkCardExists = async (cardEeid) => {
    try {
      const records = await pb.collection("cards").getList(1, 1, {
        filter: `cardEeid="${cardEeid}"`,
      });
      return records.totalItems > 0;
    } catch (err) {
      console.error(`Error checking if card exists: ${cardEeid}`, err);
      return false; // Assume it doesn't exist in case of error
    }
  };

  // Fixed version of the saveCardToPocketBase function
  const saveCardToPocketBase = async (card, currentSetName = null) => {
    // Use provided set name or default to selectedSet
    const setToCheck = currentSetName || selectedSet;

    if (card.set.name !== setToCheck && !isMultiSetSync) {
      return;
    }

    const cardEeid =
      card.name +
      "-" +
      card.number +
      "/" +
      card.set.printedTotal +
      "-" +
      card.set.id +
      "-" +
      card.regulationMark;

    // Check if card already exists
    const exists = await checkCardExists(cardEeid);
    if (exists) {
      console.log(`Card already exists, skipping: ${cardEeid}`);
      setSkippedCards(prev => prev + 1);
      setSyncedCards(prev => prev + 1); // Count as processed for progress bar
      return;
    }

    const cardId = card.number + "/" + card.set.printedTotal;
    console.log(`Saving card: ${card.name} with cardId: ${cardId}`);

    const cardData = {
      cardfile: card,
      /* Name */
      name: card.name,
      /* Id */
      cardId: cardId,
      /* regulation mark */
      cardRegulationMark: card.regulationMark,
      cardEeid: cardEeid,
      /* Set */
      setid: card.set.id,
      setname: card.set.name,
      setseries: card.set.series,
      setprintedtotal: card.set.printedTotal,
      settotal: card.set.total,
      setlegalitiesstandard: card.set.legalities.standard,
      setlegalitiesunlimited: card.set.legalities.unlimited,
      setlegalitiesexpanded: card.set.legalities.expanded,
      setptcgoCode: card.set.ptcgoCode,
      setreleaseDate: card.set.releaseDate,
      setupdatedAt: card.set.updatedAt,
      setimagesymbol: card.set.images.symbol,
      setimagelogo: card.set.images.logo,
      /* number, artist y rarity */
      number: card.number,
      artist: card.artist,
      rarity: card.rarity, // Fixed the reference to cardRarity to card.rarity
      /* nationalPokedexNumbers */
      nationalPokedexNumbers: Array.isArray(card.nationalPokedexNumbers)
        ? card.nationalPokedexNumbers.join(", ")
        : "",
      nationalPokedexNumbers_one:
        Array.isArray(card.nationalPokedexNumbers) &&
          card.nationalPokedexNumbers[0]
          ? card.nationalPokedexNumbers[0]
          : "",
      nationalPokedexNumbers_two:
        Array.isArray(card.nationalPokedexNumbers) &&
          card.nationalPokedexNumbers[1]
          ? card.nationalPokedexNumbers[1]
          : "",
      nationalPokedexNumbers_three:
        Array.isArray(card.nationalPokedexNumbers) &&
          card.nationalPokedexNumbers[2]
          ? card.nationalPokedexNumbers[2]
          : "",
      /* General */
      hp: card.hp,
      rules: card.rules?.join(", ") || "",
      types: card.types,
      attack_one: card.attacks?.[0]?.name || "",
      attack_one_damage: card.attacks?.[0]?.damage || "",
      attack_one_description: card.attacks?.[0]?.text || "",
      attack_one_energy_cost_type_one: card.attacks?.[0]?.cost[0] || "",
      attack_one_energy_cost_type_two: card.attacks?.[0]?.cost[1] || "",
      attack_one_energy_cost_type_three: card.attacks?.[0]?.cost[2] || "",
      attack_one_energy_cost_type_four: card.attacks?.[0]?.cost[3] || "",
      attack_one_energy_cost_type_five: card.attacks?.[0]?.cost[4] || "",
      attack_two: card.attacks?.[1]?.name || "",
      attack_two_damage: card.attacks?.[1]?.damage || "",
      attack_two_description: card.attacks?.[1]?.text || "",
      attack_two_energy_cost_type_one: card.attacks?.[1]?.cost[0] || "",
      attack_two_energy_cost_type_two: card.attacks?.[1]?.cost[1] || "",
      attack_two_energy_cost_type_three: card.attacks?.[1]?.cost[2] || "",
      attack_two_energy_cost_type_four: card.attacks?.[1]?.cost[3] || "",
      attack_two_energy_cost_type_five: card.attacks?.[1]?.cost[4] || "",
      hability: card.abilities?.[0]?.name || "",
      hability_description: card.abilities?.[0]?.text || "",
      weakness: card.weaknesses?.[0]?.type || "",
      resistance: card.resistances?.[0]?.type || "",
      retreatCost: card.retreatCost?.join(", ") || "",
      /* New fields */
      convertedRetreatCost: card.convertedRetreatCost || "",
      subtypes: Array.isArray(card.subtypes) ? card.subtypes.join(", ") : "",
      subtypes_1: Array.isArray(card.subtypes) && card.subtypes[0] ? card.subtypes[0] : "",
      subtypes_2: Array.isArray(card.subtypes) && card.subtypes[1] ? card.subtypes[1] : "",
      subtypes_3: Array.isArray(card.subtypes) && card.subtypes[2] ? card.subtypes[2] : "",
      supertype: card.supertype || "",
      /* legalities */
      legalitiesstandard: card.legalities.standard,
      legalitiesunlimited: card.legalities.unlimited,
      legalitiesexpanded: card.legalities.expanded,
      /* images */
      imagesmall: card.images.small,
      imageslarge: card.images.large,
    };

    try {
      const saveResponse = await pb.collection("cards").create(cardData);
      console.log(`Successfully saved: ${cardId}`, saveResponse);
      setSyncedCards(prev => prev + 1);
    } catch (err) {
      console.error(`Error saving card ${cardId} to PocketBase:`, err);
    }
  };

  useEffect(() => {
    const fetchSets = async () => {
      try {
        const response = await axios.get('https://api.pokemontcg.io/v2/sets', {
          headers: {
            'X-Api-Key': process.env.REACT_APP_POKEMON_TCG_API_KEY,
          }
        });
        // Sort sets by release date (newest first)
        const sortedSets = response.data.data.sort((a, b) =>
          new Date(b.releaseDate) - new Date(a.releaseDate)
        );
        setAvailableSets(sortedSets);
        // Set default to newest set
        if (sortedSets.length > 0) {
          setSelectedSet(sortedSets[0].name);
        }
      } catch (err) {
        console.error("Error fetching sets:", err);
        setError("Failed to fetch sets");
      }
    };
    fetchSets();
  }, []);

  const fetchCardsByName = async (name) => {
    setLoading(true);
    setError("");
    setSyncedCards(0); // Reset the counter
    setIsSyncing(true);
    let page = 1;
    let cards = [];
    try {
      while (true) {
        const response = await axios.get(
          `https://api.pokemontcg.io/v2/cards?q=name:${name}* set.name:"${selectedSet}"&page=${page}&pageSize=250`,
          {
            headers: {
              "X-Api-Key": process.env.REACT_APP_POKEMON_TCG_API_KEY,
            },
          }
        );
        cards = [...cards, ...response.data.data];
        if (response.data.data.length < 250) {
          break; // No more results
        }
        page++;
      }
      setCards(cards);
      setTotalToSync(cards.length); // Set the total number of cards to sync
      for (const card of cards) {
        await saveCardToPocketBase(card);
      }
    } catch (err) {
      console.error("Error fetching cards:", err);
      setError("Failed to fetch cards. Please try again.");
      setCards([]);
    } finally {
      setLoading(false);
      setIsSyncing(false);
    }
  };

  // New function to fetch all cards from a set
  const fetchAllCardsFromSet = async () => {
    setLoading(true);
    setError("");
    setSyncedCards(0); // Reset the counter
    setIsSyncing(true);
    setIsSyncingAll(true);
    let page = 1;
    let allCards = [];
    try {
      while (true) {
        const response = await axios.get(
          `https://api.pokemontcg.io/v2/cards?q=set.name:"${selectedSet}"&page=${page}&pageSize=250`,
          {
            headers: {
              "X-Api-Key": process.env.REACT_APP_POKEMON_TCG_API_KEY,
            },
          }
        );
        const fetchedCards = response.data.data;
        allCards = [...allCards, ...fetchedCards];
        if (fetchedCards.length < 250) {
          break; // No more results
        }
        page++;
      }
      setCards(allCards);
      setTotalToSync(allCards.length); // Set the total number of cards to sync

      console.log(`Found ${allCards.length} cards in set "${selectedSet}". Beginning sync...`);

      // Process cards in batches to avoid overwhelming the server
      for (const card of allCards) {
        await saveCardToPocketBase(card);
      }

      console.log(`Finished syncing all cards from set "${selectedSet}"`);
    } catch (err) {
      console.error("Error fetching all cards:", err);
      setError(`Failed to fetch cards from set "${selectedSet}". Please try again.`);
    } finally {
      setLoading(false);
      setIsSyncing(false);
      setIsSyncingAll(false);
    }
  };

  // Fixed syncAllSets function
  const syncAllSets = async () => {
    setIsMultiSetSync(true);
    setCurrentSyncSetIndex(0);
    setSkippedCards(0);
    setSyncedCards(0);

    // Start with the first set
    await syncNextSet(0);
  };

  // Completely revised syncNextSet function
  const syncNextSet = async (index) => {
    if (index >= availableSets.length) {
      console.log("All sets have been synced!");
      setIsMultiSetSync(false);
      setIsSyncing(false);
      setIsSyncingAll(false);
      setLoading(false);
      return;
    }

    const currentSet = availableSets[index];
    setCurrentSyncSetIndex(index);

    // Reset counters for this set
    setSyncedCards(0);
    setTotalToSync(0);

    setLoading(true);
    setError("");
    setIsSyncing(true);
    setIsSyncingAll(true);

    console.log(`Starting sync of set #${index + 1}/${availableSets.length}: ${currentSet.name}`);

    try {
      // Fetch cards for this set
      let page = 1;
      let allCards = [];

      while (true) {
        const response = await axios.get(
          `https://api.pokemontcg.io/v2/cards?q=set.id:"${currentSet.id}"&page=${page}&pageSize=250`,
          {
            headers: {
              "X-Api-Key": process.env.REACT_APP_POKEMON_TCG_API_KEY,
            },
          }
        );

        const fetchedCards = response.data.data;
        allCards = [...allCards, ...fetchedCards];

        if (fetchedCards.length < 250) {
          break; // No more results
        }

        page++;
      }

      console.log(`Found ${allCards.length} cards in set "${currentSet.name}". Beginning sync...`);
      setCards(allCards);
      setTotalToSync(allCards.length);

      // Process cards for this set
      for (const card of allCards) {
        await saveCardToPocketBase(card, currentSet.name);
      }

      const skippedCount = skippedCards;
      console.log(`Finished syncing set "${currentSet.name}". Cards skipped: ${skippedCount}, Cards added: ${syncedCards - skippedCount}`);

      // Move to the next set after a brief delay
      setTimeout(() => {
        syncNextSet(index + 1);
      }, 1000);

    } catch (err) {
      console.error(`Error syncing set "${currentSet.name}":`, err);
      setError(`Failed to sync set "${currentSet.name}". Please try again.`);

      // Allow moving to the next set despite errors
      setTimeout(() => {
        syncNextSet(index + 1);
      }, 3000);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (searchTerm.trim()) {
      fetchCardsByName(searchTerm);
    }
  };

  const handleCardSelect = (cardId) => {
    const card = cards.find((card) => card.id === cardId);
    setSelectedCard(card);
  };

  // Calculate progress percentage for the progress bar
  const syncProgress = totalToSync > 0 ? (syncedCards / totalToSync) * 100 : 0;

  // Improved progress calculation for multi-set sync
  const overallProgress = isMultiSetSync && availableSets.length > 0
    ? Math.min(
      ((currentSyncSetIndex / availableSets.length) * 100) +
      ((syncProgress / availableSets.length) * (1 / availableSets.length)),
      99.9
    )
    : 0;

  return (
    <div className="container mx-auto px-4">
      {/* Set selector */}
      <div className="flex items-center justify-center mt-5">
        <select
          value={selectedSet}
          onChange={(e) => setSelectedSet(e.target.value)}
          className="shadow border rounded py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={isSyncing}
        >
          {availableSets.map((set) => (
            <option key={set.id} value={set.name}>
              {set.name} ({set.releaseDate})
            </option>
          ))}
        </select>
      </div>

      {/* Set Actions - revised for clarity */}
      <div className="flex flex-wrap justify-center mt-5 gap-4">
        {/* Sync All Sets button */}
        <button
          onClick={syncAllSets}
          disabled={isSyncing}
          className={`bg-purple-600 hover:bg-purple-800 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${isSyncing ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          Sync All Sets (Skip Existing)
        </button>

        {/* Sync Current Set button */}
        <button
          onClick={fetchAllCardsFromSet}
          disabled={isSyncing || !selectedSet}
          className={`bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${(isSyncing || !selectedSet) ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          Sync Current Set
        </button>
      </div>

      {/* Divider */}
      <div className="flex items-center justify-center my-4">
        <div className="border-t border-gray-300 flex-grow"></div>
        <span className="px-4 text-gray-500">OR</span>
        <div className="border-t border-gray-300 flex-grow"></div>
      </div>

      {/* Search form */}
      <form onSubmit={handleSubmit} className="flex items-center justify-center mt-5">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          placeholder="Enter Pokemon Name"
          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          disabled={isSyncing}
        />
        <button
          type="submit"
          disabled={isSyncing || !searchTerm.trim()}
          className={`ml-2 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${(isSyncing || !searchTerm.trim()) ? "opacity-50 cursor-not-allowed" : ""
            }`}
        >
          Search & Sync
        </button>
      </form>

      {/* Enhanced Multi-set Sync Progress Bar */}
      {isMultiSetSync && (
        <div className="mt-5">
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium">
              Overall Progress: Set {currentSyncSetIndex + 1} of {availableSets.length} -
              {currentSyncSetIndex > 0 ? ` Completed ${currentSyncSetIndex} sets` : ''}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-3">
            <div
              className="bg-purple-600 h-3 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${overallProgress}%` }}
            />
          </div>
        </div>
      )}

      {/* Current Set Sync Progress Bar with improved stats */}
      {isSyncing && (
        <div className="mt-3">
          <div className="flex justify-between mb-1">
            <span className="text-sm font-medium">
              {isSyncingAll
                ? `Set "${availableSets[currentSyncSetIndex]?.name}": ${syncedCards} of ${totalToSync} cards (${Math.round(syncProgress)}%)`
                : `Synchronizing: ${syncedCards} of ${totalToSync} cards (${Math.round(syncProgress)}%)`
              }
            </span>
            <span className="text-sm text-gray-500">
              Skipped: {skippedCards}
            </span>
          </div>
          <div className="w-full bg-gray-200 rounded-full h-4">
            <div
              className="bg-blue-600 h-4 rounded-full transition-all duration-300 ease-in-out"
              style={{ width: `${syncProgress}%` }}
            />
          </div>
        </div>
      )}

      {/* Display loading/error messages */}
      {loading && !isSyncing && <p className="text-center mt-5">Loading...</p>}
      {error && <p className="text-center mt-5 text-red-500">{error}</p>}

      <div className="flex flex-col items-center mt-5">
        <p className="text-center">Total: {cards.length}</p>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-5">
          {cards.map((card) => (
            <div
              key={card.id}
              onClick={() => handleCardSelect(card.id)}
              className="cursor-pointer"
            >
              {card.name} - {card.number}/{card.set.printedTotal}
              <img
                src={card.images.small}
                alt={card.name}
                className="w-full h-auto"
              />
            </div>
          ))}
        </div>
        {selectedCard && (
          <div className="flex flex-row justify-between mt-5 max-w-4xl">
            <div className="flex flex-col justify-between">
              <h2 className="text-2xl font-bold">{selectedCard.name}</h2>
              <p>
                <strong>Type:</strong> {selectedCard.types}
              </p>
              <img
                src={selectedCard.images.large}
                alt={selectedCard.name}
                className="w-1/4 h-auto"
              />
              <p>
                <strong>Set:</strong> {selectedCard.set.name}
              </p>
              <p>
                <strong>Number:</strong> {selectedCard.number}
              </p>
              <p>
                <strong>Artist:</strong> {selectedCard.artist}
              </p>
            </div>
            <div className="flex flex-col justify-between">
              {selectedCard.hp && (
                <p>
                  <strong>HP:</strong> {selectedCard.hp}
                </p>
              )}
              {selectedCard.rarity && (
                <p className="hidden">
                  <strong>Rarity:</strong> {selectedCard.rarity}
                </p>
              )}
              {selectedCard.attacks && (
                <div>
                  <h3 className="font-bold">Attacks:</h3>
                  {selectedCard.attacks.map((attack, index) => (
                    <div key={index} className="mb-2">
                      <p>
                        <strong>Name:</strong> {attack.name}
                      </p>
                      <p>
                        <strong>Damage:</strong> {attack.damage}
                      </p>
                      {/*                       <p><strong>Cost:</strong> {attack.cost.join(', ')}</p>
                       */}{" "}
                      {attack.text && (
                        <p>
                          <strong>Text:</strong> {attack.text}
                        </p>
                      )}
                    </div>
                  ))}
                </div>
              )}
              {selectedCard.weaknesses && (
                <div>
                  <h3 className="font-bold">Weaknesses:</h3>
                  {selectedCard.weaknesses.map((weakness, index) => (
                    <p key={index}>
                      {weakness.type} {weakness.value}
                    </p>
                  ))}
                </div>
              )}
              {selectedCard.resistances && (
                <div>
                  <h3 className="font-bold">Resistances:</h3>
                  {selectedCard.resistances.map((resistance, index) => (
                    <p key={index}>
                      {resistance.type} {resistance.value}
                    </p>
                  ))}
                </div>
              )}
              {selectedCard.abilities && (
                <div>
                  <h3 className="font-bold">Abilities:</h3>
                  {selectedCard.abilities.map((ability, index) => (
                    <p key={index}>
                      <strong>Name:</strong> {ability.name}
                    </p>
                  ))}
                </div>
              )}
              {selectedCard.retreatCost && (
                <p>
                  <strong>Retreat Cost:</strong>{" "}
                  {selectedCard.retreatCost.join(", ")}
                </p>
              )}
              {selectedCard.rules && (
                <div>
                  <h3 className="font-bold">Rules:</h3>
                  {selectedCard.rules.map((rule, index) => (
                    <p key={index}>{rule}</p>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sync;
